// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Cfworkflows2 from "../../blocks/cfworkflows2/src/Cfworkflows2";
import Cfpipelines2 from "../../blocks/cfpipelines2/src/Cfpipelines2";
import Cfchatbotlistener2 from "../../blocks/cfchatbotlistener2/src/Cfchatbotlistener2";
import Cfutmtracking2 from "../../blocks/cfutmtracking2/src/Cfutmtracking2";
import Cfgoals2 from "../../blocks/cfgoals2/src/Cfgoals2";
import Cfcontacthistory2 from "../../blocks/cfcontacthistory2/src/Cfcontacthistory2";
import Cfdealpropertieslistener2 from "../../blocks/cfdealpropertieslistener2/src/Cfdealpropertieslistener2";
import Cfapplebusinessmessage3 from "../../blocks/cfapplebusinessmessage3/src/Cfapplebusinessmessage3";
import Cfaircallintegration2 from "../../blocks/cfaircallintegration2/src/Cfaircallintegration2";
import Cfcontactlists2 from "../../blocks/cfcontactlists2/src/Cfcontactlists2";
import Cfstages2 from "../../blocks/cfstages2/src/Cfstages2";
import Cfdealpropertiesevents2 from "../../blocks/cfdealpropertiesevents2/src/Cfdealpropertiesevents2";
import Cfsmsevents2 from "../../blocks/cfsmsevents2/src/Cfsmsevents2";
import Cftasklistener2 from "../../blocks/cftasklistener2/src/Cftasklistener2";
import Cfmergetags2 from "../../blocks/cfmergetags2/src/Cfmergetags2";
import Cfkanbanboard3 from "../../blocks/cfkanbanboard3/src/Cfkanbanboard3";
import Hashtags from "../../blocks/hashtags/src/Hashtags";
import Cfgooglemybusinessmessaging2 from "../../blocks/cfgooglemybusinessmessaging2/src/Cfgooglemybusinessmessaging2";
import Cfiptracking2 from "../../blocks/cfiptracking2/src/Cfiptracking2";
import Cfslackapi2 from "../../blocks/cfslackapi2/src/Cfslackapi2";
import Cftelephonylistener2 from "../../blocks/cftelephonylistener2/src/Cftelephonylistener2";
import Cftaskevents2 from "../../blocks/cftaskevents2/src/Cftaskevents2";
import Cfdeals2 from "../../blocks/cfdeals2/src/Cfdeals2";
import Cfemailevents2 from "../../blocks/cfemailevents2/src/Cfemailevents2";
import Cfemaillistener2 from "../../blocks/cfemaillistener2/src/Cfemaillistener2";
import Cftimedelays2 from "../../blocks/cftimedelays2/src/Cftimedelays2";
import Cfcontactpropertieslistener2 from "../../blocks/cfcontactpropertieslistener2/src/Cfcontactpropertieslistener2";
import Cfdealhistory2 from "../../blocks/cfdealhistory2/src/Cfdealhistory2";
import Cfgooglesheetsapi2 from "../../blocks/cfgooglesheetsapi2/src/Cfgooglesheetsapi2";
import Cfconditionallogic2 from "../../blocks/cfconditionallogic2/src/Cfconditionallogic2";
import Cfsmslistener2 from "../../blocks/cfsmslistener2/src/Cfsmslistener2";
import Cftelephonyevents2 from "../../blocks/cftelephonyevents2/src/Cftelephonyevents2";
import Cfheymarketintegration2 from "../../blocks/cfheymarketintegration2/src/Cfheymarketintegration2";
import Cfchatbotevents2 from "../../blocks/cfchatbotevents2/src/Cfchatbotevents2";
import Cfcontactpropertiesevents2 from "../../blocks/cfcontactpropertiesevents2/src/Cfcontactpropertiesevents2";



const routeMap = {
Cfworkflows2:{
 component:Cfworkflows2,
path:"/Cfworkflows2"},
Cfpipelines2:{
 component:Cfpipelines2,
path:"/Cfpipelines2"},
Cfchatbotlistener2:{
 component:Cfchatbotlistener2,
path:"/Cfchatbotlistener2"},
Cfutmtracking2:{
 component:Cfutmtracking2,
path:"/Cfutmtracking2"},
Cfgoals2:{
 component:Cfgoals2,
path:"/Cfgoals2"},
Cfcontacthistory2:{
 component:Cfcontacthistory2,
path:"/Cfcontacthistory2"},
Cfdealpropertieslistener2:{
 component:Cfdealpropertieslistener2,
path:"/Cfdealpropertieslistener2"},
Cfapplebusinessmessage3:{
 component:Cfapplebusinessmessage3,
path:"/Cfapplebusinessmessage3"},
Cfaircallintegration2:{
 component:Cfaircallintegration2,
path:"/Cfaircallintegration2"},
Cfcontactlists2:{
 component:Cfcontactlists2,
path:"/Cfcontactlists2"},
Cfstages2:{
 component:Cfstages2,
path:"/Cfstages2"},
Cfdealpropertiesevents2:{
 component:Cfdealpropertiesevents2,
path:"/Cfdealpropertiesevents2"},
Cfsmsevents2:{
 component:Cfsmsevents2,
path:"/Cfsmsevents2"},
Cftasklistener2:{
 component:Cftasklistener2,
path:"/Cftasklistener2"},
Cfmergetags2:{
 component:Cfmergetags2,
path:"/Cfmergetags2"},
Cfkanbanboard3:{
 component:Cfkanbanboard3,
path:"/Cfkanbanboard3"},
Hashtags:{
 component:Hashtags,
path:"/Hashtags"},
Cfgooglemybusinessmessaging2:{
 component:Cfgooglemybusinessmessaging2,
path:"/Cfgooglemybusinessmessaging2"},
Cfiptracking2:{
 component:Cfiptracking2,
path:"/Cfiptracking2"},
Cfslackapi2:{
 component:Cfslackapi2,
path:"/Cfslackapi2"},
Cftelephonylistener2:{
 component:Cftelephonylistener2,
path:"/Cftelephonylistener2"},
Cftaskevents2:{
 component:Cftaskevents2,
path:"/Cftaskevents2"},
Cfdeals2:{
 component:Cfdeals2,
path:"/Cfdeals2"},
Cfemailevents2:{
 component:Cfemailevents2,
path:"/Cfemailevents2"},
Cfemaillistener2:{
 component:Cfemaillistener2,
path:"/Cfemaillistener2"},
Cftimedelays2:{
 component:Cftimedelays2,
path:"/Cftimedelays2"},
Cfcontactpropertieslistener2:{
 component:Cfcontactpropertieslistener2,
path:"/Cfcontactpropertieslistener2"},
Cfdealhistory2:{
 component:Cfdealhistory2,
path:"/Cfdealhistory2"},
Cfgooglesheetsapi2:{
 component:Cfgooglesheetsapi2,
path:"/Cfgooglesheetsapi2"},
Cfconditionallogic2:{
 component:Cfconditionallogic2,
path:"/Cfconditionallogic2"},
Cfsmslistener2:{
 component:Cfsmslistener2,
path:"/Cfsmslistener2"},
Cftelephonyevents2:{
 component:Cftelephonyevents2,
path:"/Cftelephonyevents2"},
Cfheymarketintegration2:{
 component:Cfheymarketintegration2,
path:"/Cfheymarketintegration2"},
Cfchatbotevents2:{
 component:Cfchatbotevents2,
path:"/Cfchatbotevents2"},
Cfcontactpropertiesevents2:{
 component:Cfcontactpropertiesevents2,
path:"/Cfcontactpropertiesevents2"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;